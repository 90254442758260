<template>
  <div class="printable-sheet fade-in">
  <html><head><title>{{ characterName }} - {{ gender }} {{ raceName }} {{ setClassLevelString() }} [{{ alignment || 'Unaligned' }}]</title></head>
    <body leftmargin="0" topmargin="0" marginwidth="0" marginheight="0">
    <table cellpadding="10" cellspacing="0" border="0" valign="top" class="sheettable" width="740">
    <tr>
      <td valign="top">
        <span class="nameheader">{{ characterName || 'Name Your Character' }}</span><br/>{{ gender || 'Genderless' }} {{ raceName || 'Racially Nondescript' }} {{ setClassLevelString() }} [{{ alignment || 'Unaligned' }}]</td><td rowspan="2" width="490" valign="top"><div class="blackhead">Description</div><b>Age</b> {{ age }}<br/><b>Looks</b> {{ hairColor }} hair, {{ eyeColor }} eyes, {{ skinColor }} skin<br/><b>Height/Weight</b> {{ height }} tall, {{ weight }}lb. ({{ raceSize }})<br/><b>Homeland</b> {{ homeland }}<br/><b>Deity</b> {{ deity }}<br/><b>Campaign</b> {{ campaign }}<br/><b>Representing</b> {{ playerName }}
      </td>
    </tr>
    <tr height="40">
      <td rowspan="2" valign="top">
        <div class="d-inline-block w-50">
          <table cellpadding="1" cellspacing="0" class="sheettable w-100">
            <tr v-for="(scoreName, index) in abilityScores" :key="index">
              <td>
                <div class="blackhead">{{ scoreName }}</div>
              </td>
              <td class="boxd">{{ getAbilityScore(scoreName) }} ({{ getAbilityModifier(scoreName) }})</td>
            </tr>
          </table>
          <br/>
            <b>Initiative</b> {{ getInitiative }}
            <!-- = {{ initString }} -->
          <br/>
        </div>
        <div class="d-inline-block w-50 align-top">
          <div class="w-100 blackhead">Hit Points</div><b>HP</b> {{ getTotalHitPoints }}<br/>Current HP<br/>
          <div style="border: 1px solid #000; height: 60px;"><font size="6">&nbsp;</font></div>
          <!-- {{ healingSurge }} -->
        </div>
      </td>
    </tr>
    <tr height="80">
      <td rowspan="6" align="right" valign="top">
        <table class="sheettable">
          <tr>
            <td colspan="2"><div class="blackhead">Skills</div></td>
          </tr>
          <tr>
            <td colspan="2" align="right">SKILL &nbsp;&nbsp;Total + Ability Mod. + Ranks + Misc.</td>
          </tr>
          <tr v-for="(skill, index) in getAllSkills" :key="index">
            <td width="110" valign="top">
              <b>{{ skill.name }}</b>
            </td>
            <td width="110" valign="top">
              ({{ skillAbilityAbbrev(skill.name) }})
              {{ plusMinus(getSkillTotal(skill.name)) }}
            </td>
          </tr>
        </table>
    <br/><br/><b>* Armor Check Penalty</b> {{ getArmorCheckPenalty }}<br/>† <b>Trained Only</b><br/><br/>
    <div class="blackhead">Inventory</div>
    <span class="sheettable">
      <div v-for="(item, index) in inventory" :key="index">
        {{ item.name }}
      </div>
    </span><br/>
    <div class="blackhead">Notes</div>
    <span class="sheettable">{{ notes }}</span>
    </td></tr>
    <tr><td valign="top"><b>Base Speed</b> [ {{ raceSpeed }} ({{ (raceSpeed/5) }} sq.) ]
    <!-- {{ moveSpeedString }} -->
    <br/><br/>
    <b>AC</b> [{{ getArmorClass }}] = <span v-for="(acItem, index) in getACStringArray()" :key="index"> + {{ acItem.value }} [{{ acItem.name }}]</span><br/>
    <b>Touch AC</b> [{{ getTouchAC }}] <b>Flat-Footed</b> [{{ getFlatAC }}]<br/><br/>
    <table><tr><td><span style="width: 170;" class="blackhead">Base Attack Bonus</span></td><td><span class="boxd"> {{ plusMinus(getBaseAttackBonus) }}</span></td></tr></table>
    <!-- <b>Basic Melee Attack</b> {{ plusMinus(MaB) }}<br/><b>Basic Ranged Attack</b> {{ plusMinus(RaB) }}<br/><br/> -->
    <table>
      <tr>
        <td>
          <span style="width: 120;" class="blackhead">Fortitude Save</span>
        </td>
        <td>
          <span class="boxd"> {{ getSavingThrow('Fort', 'Constitution') }}</span>
        </td>
        <td> = {{ setModifierString(getSavingThrowStringArray('Fort', 'Constitution')) }}</td>
      </tr>
    </table>
    <table>
      <tr>
        <td>
          <span style="width: 120;" class="blackhead">Reflex Save</span>
        </td>
        <td>
          <span class="boxd"> {{ getSavingThrow('Ref', 'Dexterity') }}</span>
        </td>
        <td> = {{ setModifierString(getSavingThrowStringArray('Ref', 'Dexterity')) }}</td>
      </tr>
    </table>
    <table>
      <tr>
        <td>
          <span style="width: 120;" class="blackhead">Will Save</span>
        </td>
        <td>
          <span class="boxd"> {{ getSavingThrow('Will', 'Wisdom') }}</span>
        </td>
        <td> = {{ setModifierString(getSavingThrowStringArray('Will', 'Wisdom')) }}</td>
      </tr>
    </table><br/>
    <table>
      <tr>
        <td>
          <span style="width: 70;" class="blackhead">CMB</span>
        </td>
        <td>
          <span class="boxd"> {{ plusMinus(getCombatManeuverBonus) }}</span>
        </td>
        <td> = {{ setModifierString(getCombatManeuverBonusStringArray) }}</td>
      </tr>
    </table>
    <table>
      <tr>
        <td>
          <span style="width: 70;" class="blackhead">CMD</span>
        </td>
        <td>
          <span class="boxd"> {{ getCombatManeuverDefense }}</span>
        </td>
        <td> = {{ setModifierString(getCombatManeuverDefenseStringArray) }}</td>
      </tr>
    </table></td></tr>
    <tr><td valign="top" class="sheettable">
      <table border="0" width="410" cellpadding="1" cellspacing="0" class="sheettable">
        <tr>
          <td valign="top">
            <div class="blackhead">Feats</div>
            <div v-for="(feat, index) in characterFeats" :key="index">
              {{ feat.name }}
            </div>
            <div v-for="(userFeat, index) in userAddedFeats" :key="index">
              {{ userFeat.name }}
            </div>
          </td>
          <td valign="top">
            <div class="blackhead">Traits</div>
            <div v-for="(trait, index) in characterTraits" :key="index">
              {{ trait.name }}
            </div>
          </td>
        </tr>
        <tr>
          <td align="left" valign="top">
            <div class="blackhead">Carrying Capacity</div>
              Light Load: {{ getCarryingCapacity('light') }}lb.<br>
              Medium Load: {{ getCarryingCapacity('medium') }}lb.<br>
              Heavy Load: {{ getCarryingCapacity('heavy') }}lb.<br>
              Lift Over Head: {{ getCarryingCapacity('overHead') }}lb.<br>
              Lift Off Ground: {{ getCarryingCapacity('offGround') }}lb.<br>
              Push or Drag: {{ getCarryingCapacity('pushOrDrag') }}lb.<br>
          </td>
          <td valign="top">
            <div class="blackhead">Languages</div>
            <div v-for="(lang, index) in knownLanguages" :key="index">
              {{ lang.language }}
            </div>
          </td>
        </tr>
      </table>
    <!-- <table class="sheettable" cellpadding="10" cellspacing="0" border="0">
      <tr>
        <td valign="top">
          {{ spellsPerDay }}
        </td>
        <td valign="top">
          {{ spellsKnown }}
        </td>
      </tr>
    </table> -->
    </td></tr>
    <!-- <tr><td>
      <div class="blackhead">Special Abilities</div><b>{{ raceName }}</b><br/>{{ racialTraits }}<br/>
    </td></tr>
      <tr><td>
        {{ classFeatures }}<br/><br/>{{ hitPointRolls() }}<br/>
      </td></tr> -->
      <tr><td>
        <table>
          <tr>
            <td>
              <span style="width: 170;" class="blackhead">Experience Points</span>
            </td>
            <!-- <td>
              <span class="boxd"> {{ xp }}</span>
            </td> -->
          </tr>
        </table>
      </td></tr>
    </table>
    </body></html>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { plusMinus, abilAbbrev } from '../utils/utils';

export default {
  name: 'Print',
  created() {
    if (localStorage.getItem('latestSheet')) {
      this.$store.dispatch('loadCharacterSheet', localStorage.getItem('latestSheet'));
    }
  },
  computed: {
    ...mapState([
      'skills',
      'abilityScores',
    ]),
    ...mapState('characterSheet', [
      'characterName',
      'raceName',
      'raceSize',
      'raceSpeed',
      'racialTraits',
      'alignment',
      'age',
      'ageCategory',
      'hairColor',
      'eyeColor',
      'skinColor',
      'height',
      'weight',
      'gender',
      'homeland',
      'deity',
      'campaign',
      'playerName',
      'characterClasses',
      'characterFeats',
      'userAddedFeats',
      'characterTraits',
      'knownLanguages',
      'inventory',
      'notes',
    ]),
    ...mapGetters('characterSheet', [
      'getAbilityScore',
      'getAbilityModifier',
      'getTotalHitPoints',
      'getArmorClass',
      'getACStringArray',
      'getTouchAC',
      'getTouchACStringArray',
      'getFlatAC',
      'getFlatACStringArray',
      'getAllSkills',
      'getSavingThrow',
      'getSavingThrowStringArray',
      'getInitiative',
      'getBaseAttackBonus',
      'getCombatManeuverBonus',
      'getCombatManeuverBonusStringArray',
      'getCombatManeuverDefense',
      'getCombatManeuverDefenseStringArray',
      'getActiveClasses',
      'getSkillTotal',
      'getCarryingCapacity',
      'getArmorCheckPenalty',
    ]),
  },
  methods: {
    plusMinus,
    setClassLevelString() {
      return this.characterClasses[0].name
        ? this.getActiveClasses
          .map((clasz) => `${clasz.name} ${clasz.classLevels}`)
          .join(' | ')
        : 'Classless';
    },
    setModifierString(modArray) {
      return modArray.map((mod) => `${plusMinus(mod.value)} [${mod.name}]`).join(' ');
    },
    skillAbilityAbbrev(skillName) {
      const skillAbil = this.getAllSkills.filter((skill) => skill.name.toLowerCase() === skillName.toLowerCase());
      return abilAbbrev(skillAbil[0].abilityScore);
    },
  },
};
</script>

<style scoped lang="scss">
  html, body {
    font-family: arial, verdana, helvetica sans-serif; font-size: 11px;
    background: white !important;
    color: black !important;
  }
  table {border-color: #000000; border-collapse: collapse; font-size: 11px;}
  .sheettable{font-size: 11px;}
  .padleft {padding-left: 15px;}
  .leftbox{position: relative; float: left; padding-left: 15px;}
  #clearleft {clear: left;}
  .blackhead{display: block; font-weight: bold; font-size: 12px; text-transform: uppercase; background-color: #000000; color: #FFFFFF; text-align: center; padding: 3px;}
  .boxd {display: inline-block; min-width: 40px; padding-left: 8px; padding-right: 4px; border: 1px solid #000000; margin-top: 1px; padding-top: 3px; padding-bottom: 2px;}
  .weapsect {font-weight: bold;}
  .nameheader{font-weight: bold; font-size: 30px;}
</style>
